.card-shadow {
  box-shadow: 2px 7px 12px 7px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 2px 7px 12px 7px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 2px 7px 12px 7px rgba(0, 0, 0, 0.03);
}

.disabled {
  opacity: 0.5;
  cursor: unset !important;
}

.card-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 20px;
  margin: 20px;
  max-width: 25%;
  background-color: white;
}

.card-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-image {
  max-height: 60px;
  object-fit: contain;
  overflow: hidden;
}

.card-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  width: 90%;
  text-align: center;
  line-height: 25px;
  font-family: "Roboto";
  font-size: 1.2rem;
}

.card-desc {
  font-size: 1rem;
  text-align: center;
  line-height: 25px;
  font-family: "Roboto";
}

.card-desc-hyperlink {
  font-size: 1rem;
  text-align: center;
  line-height: 25px;
  font-family: "Roboto";
  color: #00bcd4;
  text-decoration: none;
}

.card-button {
  padding: 16px 60px 16px 60px;
  background-color: #339857;
  border: 0px;
  border-radius: 100px;
  color: white;
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .card-content {
    width: 45%;
    max-width: unset;
    padding: 10px;
    margin: 10px;
  }
  .card-title {
    font-size: 1.5rem;
  }
  .card-desc {
    font-size: 1.2rem;
  }
  .card-button {
    padding: 16px 50px 16px 50px;
    font-size: 1.2rem;
  }
  .card-image {
    max-width: 160px;
    object-fit: contain;
  }
}

@media (max-width: 768px) {
  .card-content {
    width: 43%;
    max-width: unset;
    padding: 10px;
    margin: 10px;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .card-desc {
    font-size: 1rem;
  }
  .card-button {
    padding: 16px 50px 16px 50px;
    font-size: 1rem;
  }
  .card-image {
    max-width: 150px;
    object-fit: contain;
  }
}

@media (max-width: 600px) {
  .card-content {
    width: 90%;
    max-width: unset;
    padding: 10px;
    margin: 20px 0px;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .card-desc {
    font-size: 1rem;
  }
  .card-button {
    padding: 16px 50px 16px 50px;
    font-size: 1rem;
  }
  .card-image {
    margin-top: 20px;
    max-height: 120px;
    object-fit: contain;
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .card-content {
    width: 90%;
    max-width: unset;
    padding: 10px;
    margin: 20px 0px;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .card-desc {
    font-size: 1rem;
  }
  .card-button {
    padding: 16px 50px 16px 50px;
    font-size: 1rem;
  }
  .card-image {
    margin-top: 20px;
    max-height: 100px;
    object-fit: contain;
    overflow: hidden;
  }
}

@media (max-width: 320px) {
  .card-content {
    width: 90%;
    max-width: unset;
    padding: 10px;
    margin: 20px 0px;
  }
  .card-title {
    font-size: 1rem;
  }
  .card-desc {
    font-size: 0.9rem;
  }
  .card-button {
    padding: 16px 50px 16px 50px;
    font-size: 0.9rem;
  }
  .card-image {
    margin-top: 20px;
    max-height: 100px;
    object-fit: contain;
    overflow: hidden;
  }
}
