/* Override all the text components with font family Montserrat */
body,
html {
  font-family: "Montserrat";
}

.container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff !important;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  width: 70%;
  background: transparent;
  z-index: 10;
}

.greet-bg {
  width: 100%;
  min-height: 100vh !important;
  align-items: center;
  justify-content: center;
}

.greet-bg::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: url("../../assets/Images/bg.png");
  background-size: cover;
  width: 100%;
  height: 100%;

  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.home-greeting {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.small-greet {
  color: #812522;
  font-family: "Montserrat";
  font-size: 2rem;
  font-weight: 600;
  margin: 0px;
}

.greet {
  color: 333333;
  font-family: "Montserrat";
  font-size: 4rem;
  text-align: center;
  font-weight: 400;
  line-height: 100px;
  margin: 0px;
  color: #333333;
}

.bottom-greet {
  color: 333333;
  font-family: "Montserrat";
  font-size: 1rem;
  text-align: center;
  margin: 20px 0px;
  font-weight: 600;
  color: #333333;
}

@media (max-width: 1024px) {
  .content {
    width: 85%;
  }
  .small-greet {
    font-size: 2.7rem;
    margin-bottom: 40px;
  }
  .greet {
    font-size: 5rem;
    line-height: 120px;
  }
  .bottom-greet {
    font-size: 1.5rem;
    line-height: 41px;
  }
  .home-greeting {
    min-height: 100vh !important;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .small-greet {
    font-size: 1.7rem;
    margin-bottom: 20px;
  }
  .greet {
    font-size: 3.5rem;
    line-height: 80px;
  }
  .bottom-greet {
    font-size: 1.2rem;
    line-height: 41px;
  }
}

@media (max-width: 600px) {
  .content {
    width: 90%;
  }
  .small-greet {
    font-size: 1.4rem;
  }
  .greet {
    font-size: 2.7rem;
    line-height: 66px;
  }
  .bottom-greet {
    font-size: 1rem;
    line-height: 21px;
  }
  .home-greeting {
    min-height: 90vh !important;
    justify-content: center;
  }
  .greet-bg::before {
    filter: blur(2px);
  }
}

@media (max-width: 480px) {
  .content {
    width: 90%;
  }
  .small-greet {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }
  .greet {
    font-size: 2.5rem;
    line-height: 56px;
  }
  .bottom-greet {
    font-size: 0.8rem;
    line-height: 21px;
  }
  .home-greeting {
    min-height: 90vh !important;
    justify-content: center;
  }
  .greet-bg::before {
    filter: blur(2px);
  }
}

@media (max-width: 320px) {
  .content {
    width: 90%;
  }
  .small-greet {
    font-size: 1rem;
  }
  .greet {
    font-size: 1.7rem;
    line-height: 41px;
  }
  .bottom-greet {
    font-size: 0.7rem;
    line-height: 21px;
  }
  .home-greeting {
    min-height: 55vh !important;
    justify-content: flex-start;
  }
  .greet-bg::before {
    filter: blur(2px);
  }
}
