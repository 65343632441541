.work-title {
  font-size: 2rem;
  color: 333333;
  font-family: "Montserrat";
  font-weight: 400;
}

.work-card-container {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .work-title {
    font-size: 2.4rem;
  }
  .work-card-container {
    justify-content: center;
  }
 
}

@media (max-width: 768px) {
  .work-title {
    font-size: 2rem;
  }
  .work-card-container {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .work-title {
    font-size: 1.8rem;
  }
  .work-card-container {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .work-title {
    font-size: 1.8rem;
  }
  .work-card-container {
    justify-content: center;
  }
}

@media (max-width: 320px) {
  .work-title {
    font-size: 1.5rem;
  }
  .work-card-container {
    justify-content: center;
  }
}
