.tech-stack-div {
  margin-top: 30px;
}

.tech-stack-title {
  font-size: 2rem;
  color: 333333;
  font-family: "Montserrat";
  font-weight: 400;
  margin-bottom: 50px;
}

.stack-img {
  width: 60%;
  height: auto;
  object-fit: contain;
}

.stack-div {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 40px;
  justify-items: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .tech-stack-title {
    font-size: 2.4rem;
  }
  .stack-img {
    width: 80%;
  }
  .stack-div {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 768px) {
  .tech-stack-title {
    font-size: 2rem;
  }
  .stack-img {
    width: 80%;
  }
  .stack-div {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 600px) {
  .tech-stack-title {
    font-size: 1.8rem;
  }
  .stack-img {
    width: 60%;
  }
  .stack-div {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 480px) {
  .tech-stack-title {
    font-size: 1.8rem;
  }
  .stack-img {
    width: 70%;
  }
  .stack-div {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 320px) {
  .tech-stack-title {
    font-size: 1.5rem;
  }
  .stack-img {
    width: 80%;
  }
  .stack-div {
    grid-template-columns: repeat(2, 1fr);
  }
}
