.top-nav-bar-container {
  width: inherit;
  background-color: white;
  z-index: 100;
  margin: 0px !important;
}

.top-nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  top: 0;
  background-color: white;
  flex: 1;
  z-index: 100;
  padding: 20px 0px 20px 0px;
  width: inherit
}

.shadow-activated {
  box-shadow: 0px 10px 23px -8px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0px 10px 23px -8px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 10px 23px -8px rgba(0, 0, 0, 0.09);
}

.top-nav-items {
  padding: 10px;
  padding-right: 40px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}

.top-nav-text {
  font-size: 16px;
  margin-left: 30px;
  margin-top: 40px;
  cursor: pointer;
  color: #333333;
  font-family: "Montserrat";
  text-decoration: unset !important;
}

.cdepd-logo {
  width: 200px;
  margin-left: 100px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .top-nav-text {
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
  }
  .top-nav-items {
    padding-right: 20px;
  }
  .cdepd-logo {
    width: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .top-nav-text {
    font-size: 12px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .top-nav-items {
    padding-right: 20px;
  }
  .cdepd-logo {
    width: 150px;
    margin-left: 20px;
  }
  .top-nav-bar {
    position: sticky;
  }
}

@media (max-width: 480px) {
  .top-nav-bar {
    position: sticky;
  }
  .top-nav-text {
    font-size: 12px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .top-nav-items {
    padding-right: 20px;
  }
  .cdepd-logo {
    width: 100px;
    margin-left: 20px;
  }
}

@media (max-width: 320px) {
  .top-nav-bar {
    position: sticky;
  }
  .top-nav-text {
    font-size: 12px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .top-nav-items {
    padding-right: 20px;
  }
  .cdepd-logo {
    width: 90px;
    margin-left: 20px;
  }
  
}
